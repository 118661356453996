body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: sans-serif;
  overflow-x: hidden;
  background-color: #fdfdfd;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  background-color: #fdfdfd;
  overflow-y: scroll;
}

div {
  border: 0px solid;
}

a {
  text-decoration: none;
}

.a {
  display: inline-block;
  position: relative;
  color: #f15d1e;
}

.a:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #f15d1e;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.a:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.opaquetooltip {
  opacity: 1 !important;
}

@font-face {
  font-family: 'futura400';
  src: local('futura400'),
    url(./constants/fonts/FUTURAPTLIGHT.OTF) format('OTF');
}

@font-face {
  font-family: 'futura500';
  src: local('futura500'), url(./constants/fonts/FUTURAPTBOOK.OTF) format('OTF');
}

@font-face {
  font-family: 'futura600';
  src: local('futura600'), url(./constants/fonts/FUTURAPTBOLD.OTF) format('OTF');
}

::-webkit-scrollbar {
  width: 10px;
  position: absolute;
}
::-webkit-scrollbar-track {
  background: rgb(253, 253, 253);
  border-radius: 6px;
}
::-webkit-scrollbar-thumb {
  background: #fb996c;
  border-radius: 6px;
  height: 80px;
}
::-webkit-scrollbar-thumb:hover {
  background: #02877a;
}
